<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="6">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Name" rules="required">
                  <b-form-group label="Name">
                    <b-form-input trim placeholder="Name" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Amount" rules="required">
                  <b-form-group label="Amount">
                    <b-form-input type="number" step="0.01" min="0.00" trim placeholder="Amount" v-model="dataInfo.amount" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Description">
                  <b-form-group label="Description">
                    <b-form-textarea trim placeholder="Description" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="4"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Status" rules="required">
                  <b-form-group label="Status" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <action-buttons :back-route="'cms-paymenttype-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BFormTextarea,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/cms/payment_type/store"
import router from '@/router'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import {onUnmounted, ref} from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import {toastMessage} from "@core/utils/utils"
import Vue from "vue";

export default {
  components: {
    BFormTextarea,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      name: '',
      amount: 0,
      description: '',
      status: 1,
    })

    const onSubmit = () => {

      if (dataInfo.value.amount <= 0) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Amount is required!',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'cms-paymenttype-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'cms-paymenttype-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    return {
      busy,
      dataInfo,
      refFormObserver,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
